import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/common.css'
import './styles/request-book.css'
import './styles/book-trade.css'
import './styles/book-search.css'
import './styles/library-list.css'
import './styles/search-isbn.css'
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
